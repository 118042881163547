if ($('.page-onde-encontrar').length) {
    $('.autocompleteProduto').autocomplete({
        serviceUrl: 'ajax/onde-encontrar-produtos.php',
        lookupFilter: 'query',
        minChars: 3,
        width: 370,
        appendTo: '.searchProdutos',
        beforeRender: function (container) {
            $(container.children()).each(function (index) {
                var t = $(this).html();
                t = '<div class="itemProduto">' + t + '</div>';
                $(this).html(t);
            });
            return container;
        },
        onSelect: function (suggestion) {
            //alert('You selected: ' + suggestion.value + ', ' + suggestion.data);
            $('.autocompleteProduto').css({
                color: '#ddd'
            });
            $('#codbarras').val(suggestion.data);
        }
    });

    $('.autocompleteCidade1').autocomplete({
        serviceUrl: 'ajax/soap-cidades.php',
        lookupFilter: 'query',
        minChars: 3,
        width: 'auto',
        appendTo: '.searchCidades1',
        beforeRender: function (container) {
            console.log(container, 1);

            $(container.children()).each(function (index) {
                var t = $(this).html();
                t = '<div class="itemCidade">' + t + '</div>';
                $(this).html(t);
            });
            return container;
        },
        onSelect: function (suggestion) {
            $('.autocompleteCidade1').css({
                color: '#ddd'
            });
            $('#codigoCidade1').val(suggestion.data);
        }
    });

    $('.autocompleteCidade').autocomplete({
        serviceUrl: 'ajax/soap-cidades.php',
        lookupFilter: 'query',
        minChars: 3,
        width: 'auto',
        appendTo: '.searchCidades',
        beforeRender: function (container) {
            console.log(container, 1);

            $(container.children()).each(function (index) {
                var t = $(this).html();
                t = '<div class="itemCidade">' + t + '</div>';
                $(this).html(t);
            });
            return container;
        },
        onSelect: function (suggestion) {
            $('.autocompleteCidade').css({
                color: '#ddd'
            });
            $('#codigoCidade').val(suggestion.data);
        }
    });

    $(document).ready(function () {
        $(document).on('click', '.menuOe', function () {
            if ($('.container-formulario').hasClass('close')) {
                $('.container-formulario').removeClass('close');
                $('#map,.maskLoad').removeClass('expand');
            } else {
                $('.container-formulario').addClass('close');
                $('#map,.maskLoad').addClass('expand');
            }
        });

        if ($(window).width() < 769) {
            $('.container-formulario').addClass('close');
            $('#map,.maskLoad').addClass('expand');
        }

    });

    // mobile aside
    function toggleMobileAside() {
        $('.s-findUs').toggleClass('is-mobile-active');
    }

    $('.js-s-findUs__mobileControl').on('click', function (e) {
        e.preventDefault();
        toggleMobileAside();
    });

    $('.s-findUs__aside .c-form').on('submit', function (e) {
        e.preventDefault();
        toggleMobileAside();
    });

    // toggle map/3d
    $('.js-s-findUs__toggleDecortiles').on('click', function () {
        $('.s-findUs__content__gallery').toggleClass('is-active');
        $(this).toggleClass('is-active');
        $('.s-findUs').removeClass('is-mobile-active');
    });


    // -----------
    //CARREGA MAPA
    // -----------

    let map;
    let marker1;
    let markers = [];

    function initMap() {
        if ($('#map').length) {
            var delay = 10;
            var latlng = new google.maps.LatLng(-23.575514521289506, -46.66510322435387);

            var stylez = [{
                featureType: "all",
                elementType: "all",
                stylers: [{
                    saturation: -100
                } // <-- THIS
                ]
            }];

            var mapOptions = {
                zoom: 5,
                maxZoom: 17,
                center: latlng,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControl: false,
                mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'graymap']
                }
            }
            map = new google.maps.Map(document.getElementById("map"), mapOptions);
            marker1 = new google.maps.Marker({
                position: latlng,
                map,
                icon: 'media/img/assets/pin.svg'
            });

            var mapType = new google.maps.StyledMapType(stylez, {
                name: "Grayscale"
            });
            map.mapTypes.set('graymap', mapType);
            map.setMapTypeId('graymap');

            google.maps.event.addListener(marker1, 'click', function () {
                infowindow.setContent('Casa Brasil Eliane');
                infowindow.open(map, marker1);
            });

            markers.push(marker1);

            var infowindow = new google.maps.InfoWindow();
            var geocoder = new google.maps.Geocoder();
            var bounds = new google.maps.LatLngBounds();

            function geocodeAddress(address, next, loja, idLoja, pin, tel) {
                geocoder.geocode({
                    address: address
                }, function (results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        var p = results[0].geometry.location;
                        var lat = p.lat();
                        var lng = p.lng();
                        createMarker(address, loja, tel, lat, lng, pin);
                        $('#' + idLoja).attr('data-lat', lat).attr('data-lng', lng);
                        console.log(status);
                    } else {
                        if (status == google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
                            nextAddress--;
                            delay++;
                            console.log(status);
                        } else {
                            console.log(status);
                        }
                    }
                    next();
                });
            }

            function createMarker(address, loja, tel, lat, lng, pin) {
                //var contentString = add;
                var contentString = `<div class="loja-listada">
                                            <div class="loja-info">
                                                <h3 class="titulo">${loja}</h3>
                                                <p class="endereco">${address}</p>
                                                <p class="fone">${tel}</p>
                                            </div>
                                        </div>`;
                const marker = new google.maps.Marker({
                    position: new google.maps.LatLng(lat, lng),
                    map: map,
                    icon: pin
                });
                console.log(pin);
                markers.push(marker);
                google.maps.event.addListener(marker, 'click', function () {
                    infowindow.setContent(contentString);
                    infowindow.open(map, marker);
                });

                bounds.extend(marker.position);

            }

            var locations = [];
            var nextAddress = 0;
            var endereco, lojaNome, lojaTel, idLoja, pin;

            function theNext() {
                $('.dataLoja').each(function (index) {
                    idLoja = $(this).attr('id');
                    pin = $(this).attr('data-pin');
                    endereco = $(this).find('address').html();
                    lojaNome = $(this).find('h3').html();
                    lojaTel = $(this).find('.numTel').html();
                    locations.push([endereco, lojaNome, idLoja, pin, lojaTel]);
                });
                if (nextAddress < $('.dataLoja').length) {
                    geocodeAddress(locations[nextAddress][0], theNext, locations[nextAddress][1], locations[nextAddress][2], locations[nextAddress][3], locations[nextAddress][4]);
                    console.log(locations[nextAddress][0]);
                    nextAddress++;
                    setTimeout(function () {
                        map.fitBounds(bounds);
                    }, 500)

                } else {
                    $('.maskLoad').fadeOut();
                    locations = [];
                    nextAddress = 0;
                }
            }

            function removeMarkers() {
                for (let i = 0; i < markers.length; i++) {
                    markers[i].setMap(null);
                }
                bounds = new google.maps.LatLngBounds();
            }

            $(document).on('click', '.searchNameLoja', function (e) {
                e.preventDefault();
                var form = $(this).closest('form');

                $('.tableResult').show();
                $(".loadingLojas").show();
                $("#Tcidade-lista").html('');
                $.ajax({
                    url: "ajax-soap-lojas.php",
                    type: 'POST',
                    /* Tipo da requisição */
                    data: form.serialize(),
                    success: function (data) {
                        console.log(data);
                        if (data !== '' && data !== null && data !== undefined) {
                            $("#Tcidade-lista").html(data);
                            $(".qtdResultado").show();
                            $(".qtdResultado p strong").html($('.lojasItem').length);
                            locations = [];
                            nextAddress = 0;
                            removeMarkers();
                            $('.maskLoad').fadeIn();
                            theNext();
                        } else {
                            $(".qtdResultado").hide();
                            $("#Tcidade-lista").html('<li><h4>Nenhuma loja encontrada</h4></li>');
                        }
                        $(".loadingLojas").hide();
                    }
                });
            });

            $(document).on('click', '.lojasItem', function () {
                var lat = $(this).children('.dataLoja').attr('data-lat');
                var long = $(this).children('.dataLoja').attr('data-lng');
                var lat_lng = new google.maps.LatLng(lat, long);
                map.setZoom(17);
                map.panTo(lat_lng);
                $(".results").trigger('click');
                if ($(window).width() <= 768) {
                    $('.container-formulario').addClass('close');
                    $('#map,.maskLoad').addClass('expand');
                }
            });
        }
    }
    initMap();
}
