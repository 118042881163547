// init base functions
$(window).on('load', function () {
    classOnBody($('main').attr('id'));
    scrollDirection();
    initGettersAndSetters();
    changeTouchClickText();
});
$(window).on('hashchange', function () {
    initGettersAndSetters();
});

// init AOS
if (is.not.ie()) {
    AOS.init();
} else {
    $('*').removeAttr("data-aos");
}

// nav
if ($('.l-nav').length) {
    var propsNav = {
        active: 'is-active',
        scrollInit: 0,
        scrollClassScrolled: 99,
        scrollClassMiddle: 600,
        body: $('body'),
        nav: $('.l-nav'),
        hamburguer: $('.l-nav .c-hamburguer'),
        contentHero: $('.l-nav__contentHero'),
        mask: $('.l-nav__maskMenu'),
        maskAll: $('.l-nav__maskMenu.maskAll'),
    };

    function checkResponsiveMode() {
        var resultCheck;
        var displayCheck = propsNav.hamburguer.css('display');
        if (displayCheck !== 'none') {
            resultCheck = 'is-responsive';
            propsNav.nav.removeClass('is-desktop');
            propsNav.nav.addClass('is-responsive');
        } else {
            resultCheck = 'is-desktop';
            propsNav.nav.removeClass('is-responsive');
            propsNav.nav.addClass('is-desktop');
        }
        return resultCheck;
    }
    function closeAllSubMenus() {
        $('.c-subMenu').removeClass('is-active');
    }
    function closeResponsiveMenu() {
        propsNav.hamburguer.removeClass(propsNav.active);
        propsNav.contentHero.removeClass(propsNav.active);
    }
    function hideMaskMenu() {
        propsNav.mask.removeClass(propsNav.active);
    }
    function disableOverflow() {
        propsNav.body.addClass('u-overflowBlocked');
    }
    function enableOverflow() {
        propsNav.body.removeClass('u-overflowBlocked');
    }


    // RESPONSIVE MENU
    // - open / close
    propsNav.hamburguer.on('click', function() {
        if ($(this).hasClass(propsNav.active)) {
            // close menu
            enableOverflow();
            $(this).removeClass(propsNav.active);
            propsNav.maskAll.removeClass(propsNav.active);
            propsNav.contentHero.removeClass(propsNav.active);
        } else {
            // open menu
            disableOverflow();
            $(this).addClass(propsNav.active);
            propsNav.maskAll.addClass(propsNav.active);
            propsNav.contentHero.addClass(propsNav.active);
        }
    });
    // - close
    propsNav.maskAll.on('click', function(){
        closeResponsiveMenu();
        hideMaskMenu();
        closeAllSubMenus();
        enableOverflow();
    });


    // RESPONSIVE MODE
    $(window).on('resize', function() {
        if (checkResponsiveMode() === "is-desktop") {
            if (propsNav.contentHero.hasClass(propsNav.active)) {
                closeAllSubMenus();
                closeResponsiveMenu();
                hideMaskMenu();
            }
        }
    });


    // SCROLL CHANGES
    $(window).on('load', function(event) {
        checkResponsiveMode();
        var scrollBody = $(this).scrollTop();
        if (scrollBody > 1) {
            propsNav.nav.addClass('scrolled');
        } else {
            propsNav.nav.removeClass('scrolled');
        }
    });
    $(window).on('scroll', function() {
        var scrollBody = $(this).scrollTop();
        // scroll up to 99
        if (scrollBody > propsNav.scrollClassScrolled) {
            propsNav.nav.addClass('scrolled');
        } else {
            propsNav.nav.removeClass('scrolled');
        }
        // middle class
        if (scrollBody > propsNav.scrollClassMiddle) {
            propsNav.nav.addClass('hidden');
            propsNav.nav.addClass('scrolledMiddle');
        } else {
            propsNav.nav.removeClass('hidden');
            propsNav.nav.removeClass('scrolledMiddle');
        }
        // scroll up or down
        if (scrollBody < propsNav.scrollInit) {
            propsNav.nav.removeClass('hidden');
            propsNav.nav.addClass('scrolledUp');
            propsNav.nav.removeClass('scrolledDown');
        } else {
            propsNav.nav.removeClass('scrolledUp');
            propsNav.nav.addClass('scrolledDown');
        }
        // close menus on hidden nav
        if(propsNav.nav.hasClass('hidden')) {
            closeAllSubMenus();
            hideMaskMenu();
        }
        // reference var
        propsNav.scrollInit = scrollBody;
    });

}

// modules
if ($('.m_whatsApp').length) {
    var wpp__parts = {
        link_mobile: 'http://api.whatsapp.com/',
        link_web: 'https://web.whatsapp.com/',
        phone: 'send?phone=',
        msg: 'text='
    };
    $('.js-openWhatsModule').on('click', function() {
        var wppNumber = $(this).parent('.m_whatsApp').attr("data-whatsNumber");
        openWhatsApp(wppNumber);
    });
    function openWhatsApp(number) {
        if (is.iphone() || is.androidPhone() || is.windowsPhone() || is.blackberry() || is.mobile()) {
            window.open(wpp__parts.link_mobile + wpp__parts.phone + number, '_blank');
        } else {
            window.open(wpp__parts.link_web + wpp__parts.phone + number, '_blank');
        }
    }
}
if ($('#w_ieDetect').length) {
    function showMessageOnIE(warnLevel) {
        // warnLevel = 1 -> Remover aviso
        // warnLevel = 2 -> Recolher aviso
        // warnLevel = 3 -> Bloquear navegação

        var ieDetectEl = $('#w_ieDetect');
        var ieDetectEl__mask = $('#w_ieDetect__mask');
        var ieDetectEl__unlock = $('.w_ieDetect__unlockSite');

        if (is.ie()) {
            if(warnLevel === 1) {
                $('body').css('overflow', 'hidden');
                ieDetectEl.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__unlock.on('click', function() {
                    ieDetectEl.remove();
                    ieDetectEl__mask.remove();
                    $('body').css('overflow-y', 'auto');
                    $('body').css('overflow-x', 'hidden');
                });
            }
            if(warnLevel === 2) {
                //block overflow and show modal
                $('body').css('overflow', 'hidden');
                ieDetectEl.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');

                // hide modal and mask
                ieDetectEl__unlock.on('click', function() {
                    $(this).remove();
                    ieDetectEl__mask.remove();
                    ieDetectEl.removeClass('is-visible');
                    ieDetectEl.addClass('is-fixed');
                    $('body').css('overflow-y', 'auto');
                    $('body').css('overflow-x', 'hidden');
                });
            }
            if(warnLevel === 3) {
                $('nav').remove();
                $('header').remove();
                $('footer').remove();
                $('main').remove();
                ieDetectEl__unlock.remove();
                $('body').css('overflow', 'hidden');
                ieDetectEl.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');
            }
        } else {
            ieDetectEl.remove();
            ieDetectEl__mask.remove();
        }
    }
    $(window).on('load', showMessageOnIE(3));
}
if ($('.auxElements__grid').length) {
    $('.auxElements__controls .toggleGrid').on('click', function() {
        $('.auxElements__controls .toggleGrid').toggleClass('is-active');
        $('.auxElements__grid').toggleClass('u-dnone').toggleClass('is-active');
        $('.auxElements__grid .line').toggleClass('is-active');
    });
}
if ($('.auxElements__controls').length) {
    function identifyScroll() {
        returnScroll = 1;
        if ($('body').hasClass('scrolledDown')) {
            returnScroll = 'Down';
        } else if($('body').hasClass('scrolledUp')) {
            returnScroll = 'Up';
        } else {
            returnScroll = 'Static';
        }
        return returnScroll;
    }
    function tasksAuxVars() {
        $('.auxElements__controls .scrollEfetuado').html(Math.round($(window).scrollTop()));
        $('.auxElements__controls .scrollDirection').html(identifyScroll());
        $('.auxElements__controls .viewportWidth').html(Math.round($(window).width()));
        $('.auxElements__controls .viewportHeight').html(Math.round($(window).height()));
        $('.auxElements__controls .navHeight').html(Math.round(get__navHeight()));
    }

    $(document).on('ready', function () {
        tasksAuxVars();
    });
    $(window).on('load', function () {
        tasksAuxVars();
    });
    $(window).on('resize', function () {
        tasksAuxVars();
    });
    $(window).on('scroll', function () {
        tasksAuxVars();
    });
}

// page-home.php
if ($('body.page-home').length) {
    var swiper__headerHome = new Swiper ('.swiper__headerHome', {
        speed: 400,
        spaceBetween: 0,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
    })
}

//page-produtos
if ($('body.page-produtos').length) {
    $(document).on('click', '.btn-filtro', function (e) {
        e.preventDefault();
        $(this).parent().next('ul').toggleClass('active');
        $(this).toggleClass('active');
    });

    $('#btnCarregaMaisProdutos').click(function(e) {
        e.preventDefault();
        var offset = $(this).attr('data-offset');
        var loading = $(this).attr('data-loading');
        var loaded = $(this).attr('data-loaded');
        $('#btnCarregaMaisProdutos').html(loading);
        $.ajax({
            type: "POST",
            data: {
                offset: offset,
                where: $(this).attr('data-where'),
                total_produtos: $(this).attr('data-total'),
            },
            dataType: 'json',
            url: "load-produtos",
            success: function(result) {
                $('#ulProdutos').append(result.html);
                $('#btnCarregaMaisProdutos').attr('data-offset', (parseInt(offset) + 1)).html(loaded);
                if (result.showCarregaMais === 'N') {
                    $('#btnCarregaMaisProdutos').hide();
                }
            }
        });
    });
}

//page-contato.php
if ($('#formContato').length) {

    $("#formContato").validate({
        submitHandler: function(form) {
            //SUBMIT form
            $(form).ajaxSubmit({
                //target: 'status-envio',
                beforeSubmit: function() {
                    $('#formContato .c-feedbackForm__status.--load').removeClass('u-dnone');
                    $('#formContato button').attr('disabled', true);
                },
                success: function(result) {
                    result = result.split('|');
                    $('#formContato .c-feedbackForm__status.--load').addClass('u-dnone');
                    if (parseInt(result[1]) == 1) {
                        $('#formContato .c-feedbackForm__status.--success').removeClass('u-dnone');
                        $('#formContato .c-feedbackForm__status.--success .c-feedbackForm__status__text').html(result[2]);
                        setTimeout(function() {
                            $('#formContato input#accept').prop('checked', false);
                            $('#formContato')[0].reset();
                            $('#formContato .field').blur();
                            $('#formContato button').attr('disabled', false);
                            $('#formContato .c-feedbackForm__status.--fail').addClass('u-dnone');
                            $('#formContato .c-feedbackForm__status.--success').addClass('u-dnone');
                            $('#formContato .c-feedbackForm__status.--load').addClass('u-dnone');
                        }, 2000);
                    } else {
                        $('#formContato .c-feedbackForm__status.--fail').removeClass('u-dnone');
                        $('#formContato .c-feedbackForm__status.--fail .c-feedbackForm__status__text').html(result[2]);
                        setTimeout(function() {
                            $('#formContato button').attr('disabled', false);
                            $('#formContato .c-feedbackForm__status.--fail').addClass('u-dnone');
                            $('#formContato .c-feedbackForm__status.--success').addClass('u-dnone');
                            $('#formContato .c-feedbackForm__status.--load').addClass('u-dnone');
                        }, 3000);
                    }
                }
            });
        }
    });
    /*$('body').on('change','#formContato_estado',function(){
        var uf = $(this).val();
        $("#formContato_cidade").html('<option>Carregando cidades...</option>');
        $.ajax({
            type: "POST",
            data: {uf: uf},
            url: "load-cidades",
            success: function (result) {
                $("#formContato_cidade").html(result);
            }
        });
    });*/
}

$(document).ready(function() {
    $('body').on('change', '#motivoForm', function () {
        var value = $(this).val();
        if (value !== undefined && value !== '' && value !== null) {

            //console.log('#a-txt-' + value);
            //var texto = $('#txt-' + value).html();
            $('#a-txt-' + value)[0].click();
            console.log(value);
        }
    });
})

Fancybox.bind('[data-fancybox="galery-ambientes"]', {
    infinite: false,
    caption: function (fancybox, carousel, slide) {
        //console.log(slide);
        var captionContent= $('#'+slide.caption).html();
        return (
            captionContent
        );
    },
    Toolbar: {
        display: [
            { id: "download", position: "center" },
        ],
    },
});

Fancybox.bind("[data-fancybox]", {
    Html: {
        video: {
            autoplay: false,
        },
    },
});

$('.autocomplete2').autocomplete({
    serviceUrl:  $('.formSearch2').attr('data-ajax'),
    lookupFilter: 'query',
    params: {action: 'search_Ajax'},
    type: 'POST',
    minChars: 3,
    width: 370,
    appendTo: '.searchSuggestion2',
    beforeRender: function (container) {
        $(container.children()).each(function (index) {
            var t = $(this).html().split('&lt;').join('<');
            t = t.split('&gt;').join('>');
            var a = $(this).html(t).find('img').attr('src');
            a = a.split('<strong>').join('');
            a = a.split('</strong>').join('');
            //a = a.replace('<strong>','').replace('</strong>','');
            $(this).html(t);
            $(this).find('img').attr('src', a);
        });
        return container;

    },
    onSearchStart: function (params) {
        $('.buscaFiltro2').addClass('loading');
    },
    onSearchComplete: function (query, suggestions) {
        $('.buscaFiltro2').removeClass('loading');
    },
    onHide: function (container) {
        $('.buscaFiltro2').removeClass('loading');
    },
    onSelect: function (suggestion) {
        //alert('You selected: ' + suggestion.value + ', ' + suggestion.data);
        //$('.autocomplete').css({color: '#fff'});
        $('.autocomplete2').val('').blur();
        $('.close-search2').children('svg').css('display','none');
        var url = suggestion.data;
        if(url !== '-') {
            window.location = url;
        }
    }
});

$('body').on('keyup', '#searchNav2', function(){
    if($(this).val() === '' || $(this).val() === null || $(this).val() === undefined){
        $('.close-search2').children('svg').css('display','none');
    }else{
        $('.close-search2').children('svg').css('display','block');
    }
});
$('body').on('click', '.close-search2', function(){
    $('#searchNav2').val('');
    $(this).children('svg').css('display','none');
});

$('body').on('click', '.open-search', function(){
    $('.buscar-full-screen').fadeIn('fast',function(){
        $('.buscar-full-screen').addClass('open');
        $('body').addClass('o-hidden');
    });
});

$('body').on('click', '.menu-hamburger', function(){
    $('.menu-full-screen').fadeIn('fast',function(){
        $('.menu-full-screen').addClass('open');
        $('body').addClass('o-hidden');
    });
});

$('body').on('click', '.menu-full-screen', function(e){
    if(!$(e.target).is($('.menu-full-screen *'))){
        $('.menu-full-screen').removeClass('open');
        setTimeout(function (){
            $('body').removeClass('o-hidden');
            $('.menu-full-screen').fadeOut();
        }, 700);
    }
});

$('body').on('click', '.buscar-full-screen', function(e){
    if(!$(e.target).is($('.buscar-full-screen *'))){
        $('.buscar-full-screen').removeClass('open');
        setTimeout(function (){
            $('body').removeClass('o-hidden');
            $('.buscar-full-screen').fadeOut();
        }, 500);
    }
});

$('body').on('click', '.close-menu', function(){
    $('.buscar-full-screen').removeClass('open');
    $('.menu-full-screen').removeClass('open');
    $('#searchNav2').val('');
    $('.close-search2').children('svg').css('display','none');
    setTimeout(function (){
        $('body').removeClass('o-hidden');
        $('.buscar-full-screen').fadeOut();
        $('.menu-full-screen').fadeOut();
    }, 500);
});

$('body').on('change','#serieAmbientes',function(){
    document.querySelector('a.linkCatAmbientes').setAttribute('href',$(this).val());
    document.querySelector('a.linkCatAmbientes').setAttribute('target','_self');
    document.querySelector('a.linkCatAmbientes').click();
});